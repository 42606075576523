/* index.css */
body {
  background-image: radial-gradient(circle, #4a148c 40%, #880e4f 100%);
  height: fit-content;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}


h1 {
  font-size: 32px;
  margin-bottom: 16px;
  color: #ffffff;
}

h2 {
  color: #d8d8d8;
}

h3 {
  color: #d8d8d8;
}

button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #855abb;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navbar {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.navbar .brand-left {
  font-size: 25px;
  font-weight: bold;
  margin-right: 16px;
}

.navbar a {
  color: #dbdbdb;
  text-decoration: none;
  margin-left: 16px;
  font-weight: bold;
}

.navbar a:hover {
  transform: scale(1.1);
  color: rgb(255, 255, 255);
  transition: ease-in-out 0.2s;
}

.joinbtn {
  background-color: #855abb;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.joinbtn:hover {
  background-color: #880e4f;
}

/* home */

.home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;  
  z-index: -1;
  align-items: center;
}

.background-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: radial-gradient(circle, #880e4f 20%, #4a148c 100%);
  opacity: 0;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  position: relative;
  text-align: center;
}

.home h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.home button {
  padding: 12px 24px;
  font-size: 18px;
  background-color: #855abb;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home button:hover {
  background-color: #880e4f;
}

/* About page */

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  min-height: 100vh; 
}

.about-content {
  max-width: 600px;
  margin: 0 auto;
  color: #ffffff;
  text-align: center; 
}

.about h1 {
  font-size: 36px;
  margin-bottom: 16px;
}

.about h2 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.about h3 {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.about button {
  padding: 12px 24px;
  font-size: 18px;
  background-color: #855abb;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about button:hover {
  background-color: #880e4f;
}

/* wallpaper list */

.wallpaper-list {
  color: #ffffff;
  margin-bottom: 10px;
}

.wallpapers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 0 20px; 
  margin-left: 20px; 
  margin-right: 20px; 
}

.wallpaper-card {
  padding: 10px;
  background: #5a5a5a;
  border-radius: 4px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.wallpaper-card:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
}

.wallpaper-card img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  color: #ffffff;
}

.wallpaper-info {
  padding: 10px;
}

.wallpaper-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.wallpaper-owner {
  font-size: 14px;
  color: #ffffff;
}

.wallpapers a {
  text-decoration: none;
}

/* wallpaper details */

.wallpaper-details h2 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

.wallpaper-details div {
  margin: 20px 0;
  color: #ffffff;
}

.wallpaper-details p {
  margin: 20px 0;
  color: #ffffff;
}

.wallpaper-details img {
  margin: 20px 0;
  color: #ffffff;
  width: 50%;
  height: auto;
  border-radius: 4px;
}

.wallpaper-details {
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; 
}

.wallpaper-info h3 {
  color: #ffffff;
}

.wallpaper-detail-card {
  display: flex;
  justify-content: center;
}

.image-container {
  max-width: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
}
.details-container {
  grid-column: 2;
}



/* upload new wallpaper */
.upload {
  display: flex;
  height: 100vh;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.upload label {
  text-align: left;
  display: block;
  color: #ffffff;

}
.upload h2 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 30px;
}
.upload input, .upload textarea, .upload select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.upload button {
  background-color: #855abb;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.upload button:hover {
  background-color: #880e4f;
}

/* contact form */
.contact {
  display: flex;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.contact-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.contact-social img{
  width: 4rem;
  padding: 5px;
}

.contact-form {
  width: 50%;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form label {
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 8px;
}

.contact-form button {
  padding: 8px 16px;
}

/* filter */

.explore {
  color: white;
}

.filter-area {
  font-size: 1em;
  display: inline-flex;
  margin-top: 20px;
}

/* join */

.join-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.join-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.form-group {
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}

input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #855abb;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #880e4f;
}


/* NotFound */

.not-found {
  height: 100vh;
  text-align: center;
  margin-top: 50px;
}

.not-found h2 {
  font-size: 36px;
  color: #f00;
}

.not-found p {
  font-size: 18px;
  color: #666;
}

/* faq */

.faq {
  height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.faq h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.faq-list {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
}

.faq-item p {
  display: none;
}

.faq-item.active p {
  display: block;
  font-size: 14px;
  color: #ffffff;
}
